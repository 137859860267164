<template>
    <div>
        <rockol-awards-header-winner></rockol-awards-header-winner>
        <rockol-awards-footer></rockol-awards-footer>

        <div class="fixed inset-0">
            <div class="absolute inset-0 bg-current-theme">
                <img
                    src="./../../assets/img/bg-2021.png"
                    class="mix-blend-luminosity w-full h-full object-cover">
            </div>
            <div class="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div class="app-main-view app-home relative" :class="$store.getters.withFooter">

            <div class="mx-auto flex flex-col items-center text-white  space-y-8">
                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">I Vincitori</h1>
                <div class="row space-y-2">
                    <div v-for="(blocco, idx) in winners" :key="idx"
                         class="flex flex-wrap sm:flex-nowrap justify-center w-full max-w-5xl sm:space-x-8">
                        <div v-for="(winner, idx) in blocco" :key="idx" class="w-full grid "
                             :class="{
                                'w-full': blocco.length === 1,
                                'w-1/2': blocco.length === 2,
                                'w-1/3': blocco.length === 3
                            }">
                            <div class="p-2 pb-4 text-center">
                                <div class="opacity-80 font-semibold leading-none text-center" v-html="winner.price_name"></div>
                                <div class="text-2xl font-bold leading-none mt-1 text-center" v-html="winner.artist"></div>
                                <div class="leading-none mt-1 text-center" v-if="winner.album" v-html="winner.album"></div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>

</template>

<script>
import HeaderWinner from '../page-components/HeaderWinner.vue';
import Footer from '../page-components/Footer.vue';

let winners = [
    [
        {
            price_name: 'Premio Speciale Rockol <br><span class="text-xs">Miglior album italiano 2023</span>',
            artist: 'Diodato',
            album: 'Così speciale',
        },
        {
            price_name: 'Premio Speciale Rockol <br><span class="text-xs">Miglior live italiano 2023</span>',
            artist: 'Bud Spencer Blues Explosion',
        },
        {
            price_name: 'Premio Speciale Rockol <br><span class="text-xs">Miglior artista emergente 2023</span>',
            artist: 'Daniela Pes',
        },
    ],
    [
        {
            price_name: 'Miglior album italiano 2023 <br><span class="text-xs">Per il pubblico</span>',
            artist: 'Marco Mengoni',
            album: 'Materia (Prisma)',
        },
        {
            price_name: 'Miglior live italiano 2023 <br><span class="text-xs">Per il pubblico</span>',
            artist: 'Elisa',
        },
    ],
    [
        {
            price_name: 'Premio SIAE',
            artist: 'Zef',
        },
        {
            price_name: 'Premio TicketSms',
            artist: 'Daniela Pes',
        },
        {
            price_name: 'Premio NuovoImaie',
            artist: '<span class="text-base leading-none">Maria Rita Marcotulli<br>Giulia Monti</span>',
        },
    ]
];

export default {
    data: function () {
        return {
            winners,
            juryVotes: [],
            tileCompleteList: [],
            photosDimensions: 0,
            tilesToDisplay: 0
        }
    },
    components: {
        rockolAwardsHeaderWinner: HeaderWinner,
        rockolAwardsFooter: Footer,
    }
}
</script>

<template>
    <div class="relative z-50 sm:fixed sm:top-4 w-full sm:px-4">
        <div class=" mx-auto bg-black text-white container max-w-5xl shadow p-4 px-8 sm:rounded">
            <div class="flex space-x-8 items-center justify-center">
                <router-link to="/" v-if="useVueRouter">
                    <AwardsLogo :awards-year="awardsYear"/>
                </router-link>
                <a :href="makeRoute()" v-else>
                    <AwardsLogo :awards-year="awardsYear"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import AwardsLogo from "./AwardsLogo";

    export default {
        data() {
            return {
                useVueRouter: (process.env.VUE_APP_USE_VUE_ROUTER || 'yes') === 'yes',
                awardsYear: process.env.VUE_APP_AWARDS_YEAR,
            }
        },
        methods: {
            makeRoute(route = '') {
                return (this.relativePath + route).replace(/\/\//g, "/");
            },
        },
        components: {
            AwardsLogo,
        },
    }
</script>
